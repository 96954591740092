import React, {useState} from "react"
import Layout from "../components/layout"
import Blog from "../components/landingblog"
import intro from "../assets/intro.webm"
import SEO from "../components/seo"
import numbro from "numbro";
import {renderToString} from "react-dom/server";
import Videoplayer from "../components/videoplayer";
import {graphql, Link, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import {slugify} from "../helpers";
// import MobileLeadingForm from "../components/mobileleadform";
import subtitles from "file-loader!../assets/introsubs.vtt";
const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query {
          allContentfulCaseStudy {
            edges {
              node {
                body {
                  body
                  id
                }
                id
                intro {
                  intro
                }
                title
                isHighlighted
                cover {
                  localFile {
                    url
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }

        }
    `);


    const [step, setStep] = useState(1);
    const [type, setType] = useState('application');
    const [about, setAbout] = useState('');
    const [budget, setBudget] = useState(5000);
    const [timeline, setTimeline] = useState('1 - 3 months');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);

    const stepTitle = [
        'What best describes what you are trying to build?',
        'Tell us a little bit about your project.',
        `What's your timeline for this project?`,
        'What is your estimated budget?',
        'How can we reach you?',
    ];
    const getEmail = () => {
        let formState = {
            name,
            email,
            type,
            about,
            budget,
            timeline,
        }
        return <div>
            <p>From leading form</p>
            <p>Name: {formState.name}</p>
            <p>Email: {formState.email}</p>
            <p>Type: {formState.type}</p>
            <p>Message: {formState.about}</p>
            <p>Budget: {formState.budget}</p>
            <p>Timeline: {formState.timeline}</p>
        </div>
    };
    const submitForm = async (e) => {
        e.preventDefault();
        try {
            await fetch("/.netlify/functions/sendmail", {
                method: "POST",
                body: renderToString(getEmail()),
            });
            setSent(true);
        } catch (e) {
        }
    };

    const formIsValid = () => {
        return name.length > 0 &&
            email.length > 0 &&
            type.length > 0 &&
            about.length > 0 &&
            budget > 0 &&
            timeline.length > 0
    };
    const videoJsOptions = {
        autoplay: false,
        controls: true,
        width: '528px',
        height: '297px',
        volume: 0.6,
        sources: [{
            src: intro,
            type: 'video/webm',
        }]
    }

    const mobileVideoJsOptions = {
        autoplay: false,
        controls: true,
        width: '355px',
        height: '199px',
        volume: 0.6,
        sources: [{
            src: intro,
            type: 'video/webm',
        }]
    }

    const keyEvent = (e, type, method) => {
        if (e.key === " " || e.key === "Enter" || e.key === "Spacebar") {
            method(type);
        }
    }

const highlightedCaseStudy = data.allContentfulCaseStudy.edges.filter(({node}) => node.isHighlighted).length > 0 ? data.allContentfulCaseStudy.edges.filter(({node}) => node.isHighlighted)[0].node : false;

    return (
        <Layout slimHeader={`true`}>
            <SEO title="Helping businesses succeed in their digital transformation journey" description="Websites, web applications, e-commerce solutions that meet business challenges and execute visionary ideas."/>
            <div className={'hidden lg:block landing-form-bg w-full text-white flex justify-center md:py-12'} style={{minHeight: '400px'}}>
                <div className={`container mx-auto`}>
                    <div className={'text-center'}>
                        <h1 className={'text-5xl font-bold leading-snug'}>Exceptional websites and web applications</h1>
                        {!sent && <h3 className={'text-2xl md:mb-10'}>{stepTitle[step - 1]}</h3>}
                        {sent && <div className={'flex items-center h-40 mt-10 mb-4 justify-center text-white'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.2929 8.29289L10 13.5858L7.70711 11.2929L6.29289 12.7071L10 16.4142L16.7071 9.70711L15.2929 8.29289Z"
                                      fill="#59CF8A"/>
                            </svg>
                            <h1 className={` text-lg ml-2`}>All set! We will get back to you shortly.</h1>
                        </div>}
                    </div>
                    {!sent && <div className={'mx-auto'}>
                        {step === 1 && <div className={'flex mb-4 h-40 justify-center'}>

                            <div onKeyDown={e => keyEvent(e, 'application', setType)} tabIndex={0} aria-pressed={type === 'application'} role={'button'}   onClick={e => setType('application')} className={`focus:outline-none transition-opacity transition duration-300 ease-in-out px-4 flex flex-col text-center cursor-pointer text-white ${type === 'application' ? 'opacity-100' : 'opacity-50'}`}>
                                <svg width="99" height="99" viewBox="0 0 99 99" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M98 1H1V98H98V1ZM0 0V99H99V0H0Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M23.5 2H1.5V98H23.5V2ZM0.5 1V99H24.5V1H0.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M54.5 7H28.5V11H54.5V7ZM27.5 6V12H55.5V6H27.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M48.5 83H29.5V87H48.5V83ZM28.5 82V88H49.5V82H28.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M80.5 83H61.5V87H80.5V83ZM60.5 82V88H81.5V82H60.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M81.5 19H28.5V43H81.5V19ZM27.5 18V44H82.5V18H27.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M28 51H42V52H28V51Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M28 58.5H42V59.5H28V58.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M28 66H42V67H28V66Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M28 73.5H42V74.5H28V73.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M44 51H67V52H44V51Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M44 58.5H67V59.5H44V58.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M44 66H67V67H44V66Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M44 73.5H67V74.5H44V73.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M69 51H82V52H69V51Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M69 58.5H82V59.5H69V58.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M69 66H82V67H69V66Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M69 73.5H82V74.5H69V73.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6 14.5H20V15.5H6V14.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M73 8.5H83V9.5H73V8.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M60 8.5H70V9.5H60V8.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6 22.5H20V23.5H6V22.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6 30.5H20V31.5H6V30.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6 38.5H20V39.5H6V38.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6 76.5H20V77.5H6V76.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M33 29.5L33 43.5L32 43.5L32 29.5L33 29.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M38 27L39 27L39 43.5L38 43.5L38 27Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M44 22.5L45 22.5L45 43.5L44 43.5L44 22.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M51 29.5L51 43.5L50 43.5L50 29.5L51 29.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M56 25.5L57 25.5L57 43.5L56 43.5L56 25.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M62 28.5L63 28.5L63 43.5L62 43.5L62 28.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M68 32.5L69 32.5L69 43.5L68 43.5L68 32.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M74 25L75 25L75 43.5L74 43.5L74 25Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6 82.5H20V83.5H6V82.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6 88.5H20V89.5H6V88.5Z"/>
                                </svg>
                                <p className={'w-24 py-2 font-medium'}>Web Application</p>
                            </div>
                            <div onKeyDown={e => keyEvent(e, 'b2b', setType)} tabIndex={0} aria-pressed={type === 'b2b'} role={'button'}                           onClick={e => setType('b2b')} className={`focus:outline-none transition-opacity transition duration-300 ease-in-out px-4 flex flex-col text-center cursor-pointer text-white ${type === 'b2b' ? 'opacity-100' : 'opacity-50'}`}>
                                <svg width="99" height="99" viewBox="0 0 99 99" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M98 1H1V98H98V1ZM0 0V99H99V0H0Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M87.5 15H27.5V19H87.5V15ZM26.5 14V20H88.5V14H26.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M97.5 2H1.5V23H97.5V2ZM0.5 1V24H98.5V1H0.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M41.5 33H27.5V44H41.5V33ZM26.5 32V45H42.5V32H26.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M41.5 54H27.5V65H41.5V54ZM26.5 53V66H42.5V53H26.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M41.5 75H27.5V86H41.5V75ZM26.5 74V87H42.5V74H26.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M64.5 33H50.5V44H64.5V33ZM49.5 32V45H65.5V32H49.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M64.5 54H50.5V65H64.5V54ZM49.5 53V66H65.5V53H49.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M64.5 75H50.5V86H64.5V75ZM49.5 74V87H65.5V74H49.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M87.5 33H73.5V44H87.5V33ZM72.5 32V45H88.5V32H72.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M87.5 54H73.5V65H87.5V54ZM72.5 53V66H88.5V53H72.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M87.5 75H73.5V86H87.5V75ZM72.5 74V87H88.5V74H72.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8 31.5H20V32.5H8V31.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M27 47.5H41V48.5H27V47.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M27 68.5H41V69.5H27V68.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M27 89.5H41V90.5H27V89.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M50 47.5H64V48.5H50V47.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M50 68.5H64V69.5H50V68.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M50 89.5H64V90.5H50V89.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M73 47.5H87V48.5H73V47.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M73 68.5H87V69.5H73V68.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M73 89.5H87V90.5H73V89.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M33 6.5H43V7.5H33V6.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M20 6.5H30V7.5H20V6.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7 6.5H17V7.5H7V6.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8 39.5H20V40.5H8V39.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8 47.5H20V48.5H8V47.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8 56.5H20V57.5H8V56.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M85.5 9C86.6046 9 87.5 8.10457 87.5 7C87.5 5.89543 86.6046 5 85.5 5C84.3954 5 83.5 5.89543 83.5 7C83.5 8.10457 84.3954 9 85.5 9ZM85.5 10C87.1569 10 88.5 8.65685 88.5 7C88.5 5.34315 87.1569 4 85.5 4C83.8431 4 82.5 5.34315 82.5 7C82.5 8.65685 83.8431 10 85.5 10Z"/>
                                    <g clipPath="url(#clip0)">
                                        <path
                                            d="M14.5837 18.5C14.8047 18.5 15.0166 18.5878 15.1729 18.7441C15.3292 18.9004 15.417 19.1123 15.417 19.3333C15.417 19.5543 15.3292 19.7663 15.1729 19.9226C15.0166 20.0789 14.8047 20.1667 14.5837 20.1667C14.1212 20.1667 13.7503 19.7917 13.7503 19.3333C13.7503 18.8708 14.1212 18.5 14.5837 18.5ZM7.91699 11.8333H9.27949L9.67116 12.6667H15.8337C15.9442 12.6667 16.0501 12.7106 16.1283 12.7887C16.2064 12.8668 16.2503 12.9728 16.2503 13.0833C16.2503 13.1542 16.2295 13.225 16.2003 13.2917L14.7087 15.9875C14.567 16.2417 14.292 16.4167 13.9795 16.4167H10.8753L10.5003 17.0958L10.4878 17.1458C10.4878 17.1735 10.4988 17.2 10.5183 17.2195C10.5379 17.239 10.5644 17.25 10.592 17.25H15.417V18.0833H10.417C9.95449 18.0833 9.58366 17.7083 9.58366 17.25C9.58366 17.1042 9.62116 16.9667 9.68366 16.85L10.2503 15.8292L8.75033 12.6667H7.91699V11.8333ZM10.417 18.5C10.638 18.5 10.85 18.5878 11.0062 18.7441C11.1625 18.9004 11.2503 19.1123 11.2503 19.3333C11.2503 19.5543 11.1625 19.7663 11.0062 19.9226C10.85 20.0789 10.638 20.1667 10.417 20.1667C9.95449 20.1667 9.58366 19.7917 9.58366 19.3333C9.58366 18.8708 9.95449 18.5 10.417 18.5ZM14.167 15.5833L15.3253 13.5H10.0587L11.042 15.5833H14.167Z"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect x="7.5" y="11" width="10" height="10"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <p className={'w-24 py-2 font-medium'}>E-commerce and B2B</p>
                            </div>
                            <div onKeyDown={e => keyEvent(e, 'website', setType)} tabIndex={0} aria-pressed={type === 'website'} role={'button'}               onClick={e => setType('website')} className={`focus:outline-none transition-opacity transition duration-300 ease-in-out px-4 flex flex-col text-center cursor-pointer text-white ${type === 'website' ? 'opacity-100' : 'opacity-50'}`}>
                                <svg width="99" height="99" viewBox="0 0 99 99" xmlns="http://www.w3.org/2000/svg" fill={`currentColor`}>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M98 1H1V98H98V1ZM0 0V99H99V0H0Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M29.5 13H10.5V17H29.5V13ZM9.5 12V18H30.5V12H9.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M48.5 42H29.5V46H48.5V42ZM28.5 41V47H49.5V41H28.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M70.5 42H51.5V46H70.5V42ZM50.5 41V47H71.5V41H50.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M86.5 24H10.5V51H86.5V24ZM9.5 23V52H87.5V23H9.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M86.5 83H10.5V98H86.5V83ZM9.5 82V99H87.5V82H9.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M29.5 58H10.5V74H29.5V58ZM9.5 57V75H30.5V57H9.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M58.5 58H39.5V74H58.5V58ZM38.5 57V75H59.5V57H38.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M87.5 58H68.5V74H87.5V58ZM67.5 57V75H88.5V57H67.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M60.5 14H51.5V15H60.5V14ZM50.5 13V16H61.5V13H50.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M73.5 14H64.5V15H73.5V14ZM63.5 13V16H74.5V13H63.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M86.5 14H77.5V15H86.5V14ZM76.5 13V16H87.5V13H76.5Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M23.5 30H73.5V31H23.5V30Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M33 35H67.5V36H33V35Z"/>
                                </svg>
                                <p className={'w-24 py-2 font-medium'}>Marketing Website</p>
                            </div>
                            <div onKeyDown={e => keyEvent(e, 'other', setType)} tabIndex={0} aria-pressed={type === 'other'} role={'button'}                     onClick={e => setType('other')} className={`focus:outline-none transition-opacity transition duration-300 ease-in-out px-4 flex flex-col text-center cursor-pointer text-white ${type === 'other' ? 'opacity-100' : 'opacity-50'}`}>
                                <svg width="99" height="99" viewBox="0 0 99 99" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M98 1H1V98H98V1ZM0 0V99H99V0H0Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M45 50H30V65H45V50ZM29 49V66H46V49H29Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M62.5 66C67.1944 66 71 62.1944 71 57.5C71 52.8056 67.1944 49 62.5 49C57.8056 49 54 52.8056 54 57.5C54 62.1944 57.8056 66 62.5 66ZM62.5 67C67.7467 67 72 62.7467 72 57.5C72 52.2533 67.7467 48 62.5 48C57.2533 48 53 52.2533 53 57.5C53 62.7467 57.2533 67 62.5 67Z"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M50.5003 25L40.541 43H60.4596L50.5003 25ZM50.5003 27.0656L42.2372 42H58.7634L50.5003 27.0656Z"/>
                                </svg>
                                <p className={'w-24 py-2 font-medium'}>Other</p>
                            </div>
                        </div>}
                        {step === 2 && <div className={`mb-4 max-w-xl h-40 mx-auto`}>
                            <textarea defaultValue={about} placeholder={'Got anything else to ask or tell us?'} name="about" onChange={e => setAbout(e.target.value)} className={'w-full bg-purple-700 h-32 p-4 rounded'}/>
                        </div>}
                        {step === 3 && <div className={'mx-auto mb-4 h-40 text-purple-800 max-w-sm'}>
                            <div className={'flex justify-center'}>
                                {/* eslint-disable-next-line */}
                                <div onKeyDown={e => keyEvent(e, '1 - 3 months', setTimeline)} tabIndex={0} aria-pressed={type === '1 - 3 months'}      role={'button'} className={`transition-opacity transition duration-300 ease-in-out w-32 p-2 m-2 rounded border border-white text-white cursor-pointer  hover:opacity-100 ${timeline === '1 - 3 months' ? 'opacity-100' : 'opacity-50'}`} onClick={e => setTimeline('1 - 3 months')}>1 - 3 months </div>
                                {/* eslint-disable-next-line */}
                                <div onKeyDown={e => keyEvent(e, '3 - 6 months', setTimeline)} tabIndex={0} aria-pressed={type === '3 - 6 months'}      role={'button'} className={`transition-opacity transition duration-300 ease-in-out w-32 p-2 m-2 rounded border border-white text-white cursor-pointer hover:opacity-100 ${timeline === '3 - 6 months' ? 'opacity-100' : 'opacity-50'}`} onClick={e => setTimeline('3 - 6 months')}>3 - 6 months</div>
                            </div>
                            <div className={'flex justify-center'}>
                                {/* eslint-disable-next-line */}
                                <div onKeyDown={e => keyEvent(e, '6 - 12 months', setTimeline)} tabIndex={0} aria-pressed={type === '6 - 12 months'}    role={'button'} className={`transition-opacity transition duration-300 ease-in-out w-32 p-2 m-2 rounded border border-white text-white cursor-pointer  hover:opacity-100 ${timeline === '6 - 12 months' ? 'opacity-100' : 'opacity-50'}`} onClick={e => setTimeline('6 - 12 months')}>6 - 12 months</div>
                                {/* eslint-disable-next-line */}
                                <div onKeyDown={e => keyEvent(e, '> 1 year', setTimeline)}  tabIndex={0} aria-pressed={type === '> 1 year'}             role={'button'} className={`transition-opacity transition duration-300 ease-in-out w-32 p-2 m-2 rounded border border-white text-white cursor-pointer  hover:opacity-100 ${timeline === '> 1 year' ? 'opacity-100' : 'opacity-50'}`} onClick={e => setTimeline('> 1 year')}>> 1 year</div>
                            </div>
                        </div>}
                        {step === 4 && <div className={'mx-auto mb-4 h-40  text-purple-800 max-w-lg'}>
                            <p className={'text-xl font-bold text-white'}>{budget * 1 === 5000 ? 'Up to ' : (budget * 1 === 250000 ? 'Above ' : 'Around ')} ${numbro(budget).format({thousandSeparated: true})}</p>
                            <div><input className={'bg-purple-800'} type="range" id="budget" defaultValue={budget} onChange={e => setBudget(e.target.value)} name="budget" step="5000" min="5000" max="250000"/></div>
                        </div>}
                        {step === 5 && <div className={'flex flex-col items-center  mb-4 h-40 text-purple-800'}>
                            <div className={'w-full max-w-sm'}>
                                <div className={'mb-4'}>
                                    {/* <label htmlFor="price" className="block text-sm leading-5 font-medium text-white">Name</label> */}
                                    <div className="mt-1 shadow-sm">
                                        <input id="name" onChange={e => setName(e.target.value)} className="form-input lead w-full focus:outline-none px-4 py-2 rounded" placeholder="Your name"/>
                                    </div>
                                </div>
                                <div className={'mb-4'}>
                                    {/* <label htmlFor="price" className="block text-sm leading-5 font-medium text-white">Email</label> */}
                                    <div className="mt-1 shadow-sm">
                                        <input id="email" onChange={e => setEmail(e.target.value)} className="form-input lead w-full focus:outline-none px-4 py-2 rounded" placeholder="Your email"/>
                                    </div>
                                </div>
                                <div className={'mb-4'}>
                                    {/* <label htmlFor="price" className="block text-sm leading-5 font-medium text-white">Phone</label> */}
                                    <div className="mt-1 shadow-sm">
                                        <input id="phone" onChange={e => setEmail(e.target.value)} className="form-input lead w-full focus:outline-none px-4 py-2 rounded" placeholder="Your phone"/>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <div className={'flex justify-center'}>
                            {step !== 5 && step > 1 && <button onClick={e => setStep(step - 1)} className={`px-6 py-1 rounded text-white font-medium w-32 mr-2`} style={{background: '#351992'}}>Back</button>}
                            {step !== 5 && <button onClick={e => setStep(step + 1)} className={`px-6 py-1 rounded text-white font-medium w-32`} style={{background: '#FF4D01'}}>Continue</button>}
                            {step === 5 && <button disabled={!formIsValid()} onClick={e => submitForm(e)} className={`px-4 py-1 rounded font-medium text-white `} style={{background: '#FF4D01'}}>Get your free quote</button>}
                        </div>
                    </div>}
                </div>
            </div>

            {/*<div className={'block lg:hidden'}>*/}
            {/*    <MobileLeadingForm/>*/}
            {/*</div>*/}

            <div className={'flex flex-col lg:flex-row items-center lg:items-start justify-center pt-12 pb-16 min-h-xl max-h-xl'}>
                <div className={'lg:mr-12 flex items-start px-2  hidden  lg:block'} >
                    <Videoplayer subtitles={subtitles} {...videoJsOptions} />
                </div>
                <div className={'flex items-start block lg:hidden px-2'}>
                    <Videoplayer subtitles={subtitles} {...mobileVideoJsOptions} />
                </div>
                <div className={'w-full lg:max-w-lg mx-0 px-4 lg:ml-12 pt-4 text-purple-800'}>
                    <p className={'text-xl font-bold md:mb-4'}>What drives us</p>
                    <p className={'text-base mb-8'}>
                        We believe cloud adoption and digital strength are the greatest opportunity for businesses today. We have worked with digital native companies, as well
                        as traditional companies, on their way to digital transformation. We bring cross industry expertise on the table, customer focus and a result driven approach.
                    </p>
                    <Link to={`/services`}><p className={'font font-bold'}>Learn more about our services</p></Link>    
                </div>
            </div>

            {highlightedCaseStudy && <div className={'bg-purple-800 w-full text-white flex flex-col lg:flex-row items-center justify-center mb-8'} style={{height: 580}}>
                <div className={'w-full px-4 lg:p-0 lg:w-1/3 md:mr-12 mb-4 lg:mb-0'}>
                    <Img fluid={highlightedCaseStudy.cover.localFile.childImageSharp.fluid}  alt={highlightedCaseStudy.title} />
                </div>
                <div className={'w-full m-0 px-4 lg:max-w-lg lg:ml-12 '}>
                    <p className={'font-bold'}>CASE STUDIES</p>
                    <p className={'text-3xl md:mb-4'}>{highlightedCaseStudy.title}</p>
                    <p className={'text-base mb-4'}>{highlightedCaseStudy.intro.intro}</p>
                    <p className={'font-medium'}><Link to={'/case-study/' + slugify(highlightedCaseStudy.title)} className={'max-w-lg mb-8 font-bold'}>Read case study</Link></p>
                </div>
            </div>}

            <div>
                <Blog/>
            </div>

        </Layout>
    )
}


export default IndexPage
