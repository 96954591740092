import React from 'react';
import videojs from 'video.js'
import nunuma from "../images/nunuma.webp";
import 'video.js/dist/video-js.css';
// Forest
import '@videojs/themes/dist/forest/index.css';
export default class Videoplayer extends React.Component {
    componentDidMount() {
        // instantiate Video.js
        this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
            // console.log('onPlayerReady', this)
        });
        this.player.volume(0.30);
    }
    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }

    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        return (
            <div>
                <div data-vjs-player>
                    <video poster={nunuma} ref={ node => this.videoNode = node } className="video-js vjs-theme-forest cursor-pointer" style={{minWidth: '528px', minHeight: '297px'}}>
                        <track kind="captions" label="English" src={this.props.subtitles} srcLang="en" default/>
                    </video>
                </div>
            </div>
        )
    }
}